// Functions
@use "sass:math";

// If Important
// Adds !important when true. Useful for mixins
@function if-important($important) {
  @return #{if($important, "!important", "")};
}

// Number
// Remoev units
@function number($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

// @function tint($color, $percentage) {
//     @return mix(white, $color, $percentage);
// }

// @function shade($color, $percentage) {
//     @return mix(black, $color, $percentage);
// }

@mixin line-clamp($number) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}

// font weight
@for $fw-index from 100 to 1000 {
  .fw-#{$fw-index} {
    font-weight: ($fw-index);
  }
}
@for $op-number from 0 to 100 {
  .op-#{$op-number} {
    opacity: #{0.01 * $op-number};
  }
}

// utility to make height in vh and width in vw custom class
@for $index from 1 to 500 {
  .h-vh-#{$index} {
    height: #{$index}vh !important;
  }
  .w-vw-#{$index} {
    width: #{$index}vw !important;
  }
}
// utility to make min height in vh and width in vw custom class
@for $index from 1 to 500 {
  .h-min-vh-#{$index} {
    min-height: #{$index}vh !important;
  }
  .w-min-vw-#{$index} {
    min-width: #{$index}vw !important;
  }
}

// utility to make width custom and height in %
@for $index from 1 to 100 {
  .w-#{$index} {
    width: (1% * $index) !important;
  }

  .h-#{$index} {
    height: (1% * $index) !important;
  }
}

// utility to make letter spacing custom and height in %
@for $index from 1 to 50 {
  .ls-#{$index} {
    letter-spacing: #{0.1 * $index}px !important;
  }
}

$root-font-size: 16;
// Font Sizes
$i: 8;
@while $i<=166 {
  .fs-#{$i} {
    font-size: #{calc($i / $root-font-size)}rem;
  }

  $i: $i + 1;
}

@media (max-width: $mediumScreen) {
  $root-font-size: 20;

  // Regenerate styles using updated font size
  $i: 8;
  @while $i <= 166 {
    .fs-#{$i} {
      font-size: #{calc($i / $root-font-size)}rem;
    }

    $i: $i + 1;
  }
}
@media (max-width: $smallScreen) {
  $root-font-size: 24;

  // Regenerate styles using updated font size
  $i: 8;
  @while $i <= 166 {
    .fs-sm-#{$i} {
      font-size: #{calc($i / ($root-font-size - 8))}rem !important;
    }
    .fs-#{$i} {
      font-size: #{calc($i / $root-font-size)}rem;
    }

    $i: $i + 1;
  }
}
