.text-truncate-2 {
  @include line-clamp(2);
}
.text-truncate-3 {
  @include line-clamp(3);
}
.cursor-pointer {
  cursor: pointer;
}
.color-light {
  color: $text-color;
}

.color-dark {
  color: $dark-color;
}

.black-color {
  color: $black-shade !important;
}
.text-black-1 {
  color: $black-shade-1;
}
.text-black-2 {
  color: $black-shade-2;
}
.text-black-3 {
  color: $black-shade-3;
}
.text-black-5 {
  color: $black-shade-5;
}

.color-s {
  color: $secondary-color;
}

.bg-color-s {
  background-color: $secondary-color;
}
.bg-white-3 {
  background-color: $white-shade-3;
}

.bg-white-op-15 {
  background-color: rgba($white-shade, 0.15);
}

.textarea {
  resize: none !important;
}

.color-p {
  color: $primary-color;
}

.bg-color-p {
  background-color: $primary-color;
}

a {
  color: $link-color;
  text-decoration: none;
}

.title {
  color: $black-title-shade;
}
.title-1 {
  color: $black-shade;
}

.bg-color-gray {
  background-color: $light-gray-shade;
}

.wh-pre-wrap {
  white-space: pre-wrap;
}

.box-shadow-1 {
  box-shadow: 4.583px 4.583px 9.165px 0px rgba(0, 0, 0, 0.05);
}

.border-card {
  border: 0.75px solid $white-shade-2;
}
