.btn {
  border-radius: 1.5rem;
  background-color: $light-gray-shade;
  border: none;
  outline: none;
  &-primary {
    color: $primary-color;
  }
  &-progress {
    color: $primary-color;
    background-color: $light-gray-shade;
  }
  &-completed {
    color: $green-shade-1;
    background-color: rgba($green-shade, 24%);
  }

  &-confirm {
    color: $primary-color-dark-1;
    &:hover,
    &:active {
      color: $primary-color-dark-1 !important;
      background-color: $white-shade !important;
      opacity: 0.9;
    }
  }
  &-cancel {
    color: $white-shade-4;
    background-color: transparent;
    border: 1px solid $white-shade-4;
    &:hover,
    &:active {
      color: $white-shade-4 !important;
      background-color: inherit !important;
      border-color: $white-shade-4 !important;
      opacity: 0.7;
    }
  }
}

.continue-btn {
  border: 1px solid $primary-color;
  color: $primary-color !important;
  background-color: $white-shade !important;
  &:hover {
    opacity: 0.8;
  }
}

.default-btn {
  background-color: $primary-color;
  color: $white-shade;

  &:disabled {
    background-color: $gray-shade-1;
    color: $dark-color;
  }
}

.default-btn:hover {
  background-color: $primary-color-dark !important;
}

.grey-btn {
  background-color: $palette-shade;
  color: $black-shade;
  border: none;

  &:hover {
    background-color: $palette-shade !important;
    color: $black-shade !important;
    border: none !important;
  }
  // border: 1px solid $primary-color-dark;
}
