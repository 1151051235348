@import "~bootstrap/scss/bootstrap";
@import "react-toastify/dist/ReactToastify.css";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap");
@import "react-circular-progressbar/dist/styles.css";
// abstract declaration
@import "./abstracts/variables";
@import "./abstracts/util";
@import "./abstracts/theme";

// component declaration
@import "./components/button";
@import "./components/modal";

//steps
@import "./steps.scss";
@import "./helper.scss";
* {
  font-family: "Source Sans 3", sans-serif;
}

body {
  font-family: "Source Sans 3", sans-serif;
  background-image: url(../assets/steps/circle-green.webp),
    url(../assets/steps/curve-blue.webp);
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 35%, 50%;
  min-height: 100vh;
  color: $black-shade;
  // padding-bottom: 2rem;

  @media (min-width: $smallScreen) {
    background-size: 30%, 40%;
  }
  @media (min-width: $mediumScreen) {
    background-size: 25%, 25%;
  }
}
