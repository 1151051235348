// theme color definitions
$enable-negative-margins: true;

$primary-color: #4a60ab;
$primary-color-1: #309dec;
$primary-color-2: #8ec0fd;

$secondary-color: #f99f22;
$secondary-color-opaque: rgba(249, 159, 34, 0.5);

$text-color: #888888;
$dark-color: #2f3032;
$link-color: #45a2e5;
$light-gray-shade: #f0f0fa;
$gray-1: #a7aeb6;
$gray-2: #8f8c8c;
$gray-3: #d2d2d2;
$palette-shade: #e3e3e3;

$white-shade: #fff;
$white-shade-1: #f2f2f2;
$white-shade-2: #f7f7f7;
$white-shade-3: #f9f9f9;
$white-shade-4: #fafafa;

$black-title-shade: #383530;
$primary-color-dark: #2a3661;
$primary-color-dark-1: #1c3a5b;
$shadow-color: rgba(0, 0, 0, 0.04);
$green-shade: #a2bb29;
$green-shade-1: #63721a;
$yellow-shade: #fdcb1e;
$orange-shade: #fad499;
$orange-shade-text: #e9a02e;
$avatar-border: #f5ab38;

$black-shade: #000000;
$black-shade-1: #121212;
$black-shade-2: #222;
$black-shade-3: #383836;
$black-shade-4: #0e0e0e;
$black-shade-5: #444444;

$red: #ff0000;

$yellow-dark-shade: #e9b502;
$yellow-botton-text: #abc724;
$yellow-light-shade: #e8f7a3;
$purple-shade: #9146a4;
$primary-color-light: #d0dbff;
$purple-dark-shade: #8b3b9f;
$very-dark-purple: #3a0049;
$purple-border-shade: #112c86;
$purple-light-shade: #8199e9;
$dark-purple-color: #420a51;

$gray-shade: #b7b7bd;
$gray-shade-1: #ddd;
$gray-shade-2: #d9d9d9;

$brown-shade: #333333;
$light-skin: #f5f5f5;
$epic-win: #595959;
$heart-text: #c02d62;
$bg-heart: #ff9cbf;
$phy-text: #e59311;
$phy-guard: #fad499;
$bonus-guard: #e799fa;

$background-1: #e0eeff;
$background-2: #ffedd1;
$background-3: #e1edbf;
$background-4: #ece9ff;

$purple-1: #96a5da;

$smallScreen: 576px;
$mediumScreen: 768px;
$largeScreen: 992px;
$xLargeScreen: 1200px;
$xxLargeScreen: 1400px;
