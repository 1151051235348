.tasks {
  top: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0rem;

  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 45%, 50%;

  &.step-1 {
    background-color: $background-1;
    background-image: url(../assets/steps/circle-blue.webp),
      url(../assets/steps/curve-pink.webp);
  }

  &.step-2,
  &.step-5 {
    background-color: $background-2;
    background-image: url(../assets/steps/circle-yellow.webp),
      url(../assets/steps/curve-green.webp);
  }
  &.step-3 {
    background-color: $background-3;
    background-image: url(../assets/steps/circle-yellow.webp),
      url(../assets/steps/curve-yellow.webp);
  }
  &.step-4 {
    background-color: $background-4;
    background-image: url(../assets/steps/circle-yellow.webp),
      url(../assets/steps/curve-pink.webp);
  }

  .top-container {
    position: sticky;
    z-index: 500;
    top: 0;
    background-color: $white-shade;
    padding: 0.5rem 0.5rem;
    border-radius: 0px 0px 0.75rem 0.75rem;
    border: 1px solid $gray-3;
    .multiple-stepper {
      .icon-container {
        border: 0.5px solid $gray-shade;
        border-radius: 50%;
        padding: 0.1875rem;
        color: $gray-shade;

        .icon {
          width: 3rem;
          height: 3rem;
          background: transparent;
        }

        &.progressing {
          border: 0.5px solid $purple-1;
          color: $white-shade;
          .icon {
            background: no-repeat center / contain
              url(../assets//steps/bg-icon-progressing.svg);
          }
        }
        &.completed {
          border: 0.5px solid transparent;
          color: $white-shade;
          .icon {
            background: no-repeat center / contain
              url(../assets//steps/bg-icon-completed.svg);
          }
        }
      }

      .bottom-container {
        .task {
          color: $gray-1;
        }
        .title {
          color: $gray-2;
        }
        &.progressing {
          .task {
            color: $primary-color;
          }
          .title {
            color: $black-title-shade;
          }
        }
      }
    }

    @media (min-width: $largeScreen) {
      padding: 2rem 4.1875rem;
      border-radius: 0.75rem;
      border: none;
      position: relative;
    }
  }

  .step-container {
    background-color: $white-shade;
    width: 95%;
    margin: 0 auto;
    padding: 1rem 0.75rem;
    border-radius: 0.75rem;
    border: 1px solid $gray-3;
    .layout {
      min-height: 80vh;
    }

    @media (min-width: $smallScreen) {
      background-image: url(../assets//logo.svg);
      background-position: bottom 10px right 20px;
      background-size: 60px 60px;
      background-repeat: no-repeat;
      .layout {
        min-height: 60vh;
      }
    }
    @media (min-width: $largeScreen) {
      padding: 2rem 4.1875rem 3rem;
      border-radius: 0.75rem;
      // position: relative;
      width: 100%;

      border: none;
    }
  }

  @media (min-width: $largeScreen) {
    padding: 5rem;
    background-size: 25%, 25%;
  }
}

.progress {
  background-color: $white-shade-1;
  height: 0.5rem;

  &-bar {
    background-color: $primary-color;
  }

  &.completed {
    .progress-bar {
      background-color: $green-shade;
    }
  }
}

.video-player {
  position: relative;
  margin: 0 auto;
  width: 100%;

  video {
    width: 100%;
    height: 100%;
    &::cue {
      font-size: 1rem;
      transition: all 1s ease-out;
      font-style: italic;
      line-height: 2rem;
    }

    @media (min-width: $smallScreen) {
      &::cue {
        font-size: 1.5rem;

        line-height: 6rem;
      }
    }
  }
  .play {
    visibility: hidden;
  }
  .controls {
    visibility: hidden;
    position: absolute;
    bottom: 3px;
    z-index: 2147483647;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 0.5rem 0.5rem;
    background: linear-gradient(0deg, #111 -3.27%, rgba(12, 12, 12, 0) 100%);
    input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      height: 4px;
      width: 100%;
      cursor: pointer;
      outline: none;
      overflow: hidden;
      border-radius: 16px;
      background-color: rgba($gray-shade-2, 0.5);

      &:hover,
      &:active {
        opacity: 0.8;
      }
    }

    /* Track: webkit browsers */
    input[type="range"]::-webkit-slider-runnable-track {
      height: 4px;
      background-color: rgba($gray-shade-2, 0.5);
      border-radius: 16px;
    }

    /* Track: Mozilla Firefox */
    input[type="range"]::-moz-range-track {
      height: 4px;
      background-color: rgba($gray-shade-2, 0.5);
      border-radius: 16px;
    }

    /* Thumb: webkit */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 4px;
      width: 0;
      background-color: $red;
      border-radius: 50%;
      border: 2px solid $red;
      /*  slider progress trick  */
      box-shadow: -1000px 0 0 1000px $red;
    }

    /* Thumb: Firefox */
    input[type="range"]::-moz-range-thumb {
      height: 4px;
      width: 15px;
      background-color: rgba($gray-shade-2, 0.5);
      border-radius: 50%;
      border: 1px solid $red;
      /*  slider progress trick  */
      box-shadow: -1000px 0 0 1000px $red;
    }
    button {
      color: $white-shade;
      margin-right: 0.3rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: none;
    }
  }
  &:hover {
    .controls {
      visibility: visible;
    }
    .play {
      visibility: visible;
    }
  }

  .dropdown {
    &-toggle {
      &:active {
        background-color: transparent;
      }
      &::after,
      &::before {
        display: none;
      }
    }
    &-menu {
      background-color: $black-shade;
      opacity: 0.9;
    }
    &-item {
      &.active,
      &:active {
        background-color: rgba(222, 226, 230, 0.25);
      }
    }
  }
}
