.custom-modal {
  &.fade {
    background-color: rgba($black-shade-4, 0.7);
  }

  .correct {
    color: $secondary-color;
  }
  .incorrect {
    color: $primary-color-2;
  }

  .description {
    color: $white-shade-4;
  }
}
